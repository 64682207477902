<template>

	<div class="page" id="try-now">

		<div class="page-heading">
			<h2>Want to use vineco?</h2>
			<h1>Try or buy!</h1>
		</div>

		<div class="container">
			<div class="row no-gutters block-layout">
				<div class="col-12 col-lg block-item block-accent block-image left">

					<img src="../assets/front-page/ecological.jpg" alt="Ecological"/>

					<div class="block-content text-white">
						<h4>Try or buy</h4>
						<h3 class="text-primary">Albert Heijn</h3>
						<p class="lead">
							Try vineco by spending over €50 euros on an Albert Heijn crate. It's also available in the local store.
						</p>
						<button class="btn btn-contained btn-primary"><span>Find store</span></button>
					</div>

				</div>
				<div class="col-12 col-lg block-item block-accent block-image right">

					<img src="../assets/front-page/environment.jpg" alt="Environment"/>

				</div>
			</div>
		</div>

	</div>

</template>

<script>

	export default {

		name: "try-now"

	}

</script>

<style lang="scss" scoped>

</style>
